<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t("edit") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="iotDeviceModel.iot_device_make_id"
                  dense
                  :items="iotDeviceMakesList"
                  item-text="name"
                  item-value="id"
                  :label="$t('manufacturer_name')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="iotDeviceModel.name"
                  dense
                  :label="$t('model_name_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="iotDeviceModel.flespi_device_type_id"
                  dense
                  type="number"
                  :label="$t('flespi_device_type_id_*')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="toggleEdit()"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="isLoadingSave"
            @click="updateItem(iotDeviceModel)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    iotDeviceModel: {
      type: Object,
      default() {
        return "";
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
      iotDeviceMakes: [],
    };
  },
  computed: {
    ...mapGetters({
      iotDeviceMakesList: "iotDeviceMakes/iotDeviceMakesList",
    }),
  },
  methods: {
    async updateItem(iotDeviceModel) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        await this.$store
          .dispatch("iotDeviceModels/update", iotDeviceModel)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            this.isLoadingSave = false;
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>
